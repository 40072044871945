var $ = require('jquery')

import 'intersection-observer'
import { CountUp } from 'countup.js'
import 'alpinejs'
import 'popper.js'
import 'bootstrap/js/dist/util'
import 'bootstrap'
import 'lightbox2'
import './Js/bootstrap.navbar'
import './Js/bootstrap.smoothscroll'
import './Js/bootstrap.stickyheader'
import Macy from 'macy'
import { Swiper, Autoplay, Navigation, Pagination, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'
import './scss/app.scss'

Alpine.start()
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

if (document.querySelector('.masonary-sort') !== null) {
    const macyInstance = Macy({
        container: '.masonary-sort',
        trueOrder: true,
        waitForImages: false,
        margin: 20,
        columns: 3,
        breakAt: {
            992: 2,
            712: 1,
        }
    })

    macyInstance.runOnImageLoad(function () {
        macyInstance.recalculate(true);
    }, true)
}


$(document).ready(function() {
    $.each($('.counter'), function() {
        var endval = $(this).data('end-val')
        var duration = $(this).data('duration')
        var suffix = $(this).data('suffix')

        var options = {
            useEasing: false,
            useGrouping: true,
            separator: '',
            decimal: ',',
            duration,
            suffix: typeof suffix === 'string' ? suffix : ''
        }

        const counter = new CountUp(this, endval)

        const observer = new IntersectionObserver(entries => {
            entries.map(entry => {
                if (entry.isIntersecting) {
                    counter.start()
                    observer.unobserve(entry.target)
                }
            })
        })

        observer.observe(this)
    })

    const swiperFullscreenContainer = document.querySelector(
        '.swiper-fullscreen-container'
    )
    if (swiperFullscreenContainer !== null) {
        new Swiper(swiperFullscreenContainer, {
            direction: 'horizontal',
            loop: swiperFullscreenContainer.dataset.loop,
            speed: parseInt(swiperFullscreenContainer.dataset.speed),
            effect: swiperFullscreenContainer.dataset.effect,
            slidesPerView: 1,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function(index, className) {
                    return (
                        '<span class="' + className + '"><span></span></span>'
                    )
                }
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })
    }

    const swiperMultiSlides = document.querySelectorAll(
        '.swiper-multi-slides-container'
    )
    
    swiperMultiSlides.forEach(swiperContainer => {
        let options = {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 4,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup:
                      swiperContainer.dataset.scrollslides === 'group' ? 2 : 1
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup:
                      swiperContainer.dataset.scrollslides === 'group' ? 4 : 1
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup:
                      swiperContainer.dataset.scrollslides === 'group' ? 5 : 1
                }
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };
        
        if (swiperContainer.dataset.autoplay === 'true') {
            options.autoplay = {
                delay: swiperContainer.dataset.speed,
                  disableOnInteraction: false
            };
        }
        
        new Swiper(swiperContainer, options)
    })
    /*
    swiperMultiSlides.forEach(swiperContainer => {
        new Swiper(swiperContainer, {
            direction: 'horizontal',
            loop: true,
            speed: parseInt(swiperContainer.dataset.speed),
            slidesPerView: 1,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup:
                        swiperContainer.dataset.scrollslides === 'group' ? 2 : 1
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup:
                        swiperContainer.dataset.scrollslides === 'group' ? 4 : 1
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup:
                        swiperContainer.dataset.scrollslides === 'group' ? 5 : 1
                }
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })
    })*/

    const swiperAdvisors = document.querySelectorAll(
        '.swiper-advisors-slides-container'
    )
    swiperAdvisors.forEach(swiperAdvisorsContainer => {
        new Swiper(swiperAdvisorsContainer, {
            direction: 'horizontal',
            loop: true,
            speed: 400,
            slidesPerView: 1,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 3
                }
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })
    });

    const $formControl = $(".form-control")
    if ($formControl.length) {
        $formControl.focus(function(){
            $(this).parent().addClass("is-active is-completed");
        });

        $formControl.focusout(function(){
            const $t = $(this)
            const $p = $t.parent()

            if($t.val() === "")
                $p.removeClass("is-completed");
            $p.removeClass("is-active");
        })
    }
    
})